import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Image from "gatsby-image"

import { media } from "../styles/media"
import Layout from "../components/Layout"

import { CgSmartphoneShake } from "react-icons/cg"
import { ImBlog } from "react-icons/im"
import { IconContext } from "react-icons"

import BackImage from "../images/backgroundBlack.png"

import SEO from '../components/SEO'



const StyledWrap = styled.div`
  width: 100%;
  position: relative;
`
const StyledBack = styled.div`
  position: absolute;
  background: url(${BackImage});
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
`

const StyledContents = styled.div`
  padding:50px 5%;
  position:relative;
  ${() => media.tablet(css`
    max-width:600px;
    margin:0 auto;
  `
)}
`
const StyledHead = styled.div`
  padding:0 0 40px;
  h2{
    font-size:1.32em;
    text-align:right;
  }
  p{
    width:100%;
    text-align:right;
  }
`
const StyledBody = styled.div`
  p{
    color:${({ theme }) => theme.colors.primary.beige};
  }
  h3{
    font-size:1.62em;
    margin-bottom:30px;
  }
  .gatsby-image-wrapper{
    width:100%;
  }
  .blogBody{
    color:white;
    margin:20px 0;
    p{
      color:white;
      padding:1.62em 0;
      line-height:1.62em;
    }
  }
`

const StyledFooter = styled.div`
  display:flex;
  flex-flow:column nowrap;
  align-items:center;
  justify-content:center;
  a{
    display:flex;
    flex-flow:row wrap;
    align-items:center;
    justify-content:center;
    margin-bottom:1.62em;
    &:after{
      width:30%;
      height:2px;
      margin:10px 0;
      display:block;
      content:"";
      background:${({ theme }) => theme.colors.primary.beige};
      transition:.8s;
    }
    svg{
      margin-right:.32em;
    }
    &:hover{
      &:after{
        background:white;
        width:100%;
      }
    }
  }
`


const IconContact = () => (
  <IconContext.Provider value={{ color: "gray", size: "20px" }}>
    <CgSmartphoneShake />
  </IconContext.Provider>
)
const IconBlog = () => (
  <IconContext.Provider value={{ color: "gray", size: "20px" }}>
    <ImBlog />
  </IconContext.Provider>
)


const Blogs = ({ data }) => {
  const { blog } = data
  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": 'https://aleenta-rr.jp/',
          "name": 'アリンタロイヤルリゾート'
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": 'https://aleenta-rr.jp/release/',
          "name": 'Aleenta Blog'
        }
      },
      {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `https://aleenta-rr.jp/release/${data.blog.slug}`,
          "name": `${data.blog.blogTitle}`
        }
      }]
    }
  ]
  return (
    <Layout>
      <SEO
        title={data.blog.blogTitle}
        jsonld={jsonld}
        pageUrl={'https://aleenta-rr.jp/release/' + data.blog.slug}
        description={data.blog.blogContents}
        type='article'
        imageUrl={data.blog.blogMedia.absolutePath}
      />
      <StyledWrap>
        <StyledBack></StyledBack>
        <StyledContents>
          <StyledHead>
            <h2>Aleenta Blog</h2>
            <p>アリンタ・ロイヤル・リゾートの最新情報など</p>
          </StyledHead>
          <StyledBody>
            <p>{data.blog.blogDate}</p>
            <h3>{data.blog.blogTitle}</h3>
            <Image fluid={data.blog.blogMedia.childImageSharp.fluid} alt="アリンタロイヤルリゾートのブログ" />
            <div className="blogBody" dangerouslySetInnerHTML={{ __html: data.blog.blogContents }} />
          </StyledBody>
          <StyledFooter>
            <Link to="/release/"><IconBlog />Blogトップ</Link>
            <Link to="/inquiry/"><IconContact />お問合せはこちら</Link>
          </StyledFooter>
        </StyledContents>
      </StyledWrap>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String) {
    blog: strapiAleentaBlog(slug: { eq: $slug }) {
      blogTitle
      blogContents
      slug
      blogDate
      blogMedia {
        absolutePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`


export default Blogs
